import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import DemonSlayerView from "@/views/DemonSlayerView.vue";
import SoulQuestView from "@/views/SoulQuestView.vue";

const routes = [
    {
      path: "/",
      name: "HomeView",
      component: HomeView,
    },
    {
      path: "/DemonSlayer",
      name: "DemonSlayerView",
      component: DemonSlayerView,
    },
    {
      path: "/SoulQuest",
      name: "SoulQuestView",
      component: SoulQuestView,
    }
  ];

  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  });
  
  export default router;