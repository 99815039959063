<template>
  <section class="view">
    <div class="slides slowFade box">
        <div class="slide">
            <img src="../assets/Zombies.png" alt="img"/>
        </div>
        <div class="slide">
            <img src="../assets/nether.jpg" alt="img"/>
        </div>
        <div class="slide">
            <img src="../assets/hogwarts3.jpg" alt="img"/>
        </div> 
        <div class="slide">
            <img src="../assets/nether2.jpg" alt="img"/>
        </div>
        <div class="slide">
            <img src="../assets/end.png" alt="img"/>
        </div>
        <div class="slide">
            <img src="../assets/YourWorld.png" alt="img"/>
        </div>
    </div>
    <div class="box overlay">
      <h1>Welcome to Crusader Games</h1>
      <img id="logo" style="z-index: 1;" alt="Crusader Games logo" src="../assets/logo.png" />      
      <h2>Play our free Minecraft server</h2>
      <ol>
        <li>Launch Minecraft</li>
        <li>Click on Multiplayer</li>
        <li>Connect to Server Address below</li>
      </ol>
      <p><span style="font-size: 40px; color: red;">play.crusadergames.net</span></p>
    </div>
    <!-- <div class="container">
      <img id="logo" alt="Crusader Games logo" src="../assets/logo.png" />
      <p style="font-size: 25px;">Welcome to Crusader Games</p>
      <p>Play our free minecraft server at  <span style="color: darkred;">PLAY.CRUSADERGAMES.NET</span></p>
    </div> -->
  </section>
</template>

<script>
export default {
  name: "HomeView",
};
</script>

<style scoped>
.box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.overlay {
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  opacity: 0.8;
}

.slowFade {
    display: flex;
    align-items: flex-start;
    background: #fff;
    height: 100vh;
    overflow: hidden;
    position: relative;
    width: 100%;
}
.slowFade .slide img {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    height: 100%;
    background: #000;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    opacity: 0;
    transform: scale(1.5) rotate(15deg);
    -webkit-animation: slowFade 32s infinite;
            animation: slowFade 32s infinite;
}
.slowFade .slide:nth-child(3) img {
    -webkit-animation-delay: 8s;
            animation-delay: 8s;
}
.slowFade .slide:nth-child(2) img {
    -webkit-animation-delay: 16s;
            animation-delay: 16s;
}
.slowFade .slide:nth-child(1) img {
    -webkit-animation-delay: 24s;
            animation-delay: 24s;
}

@keyframes slowFade {
    25% {
        opacity: 1;
        transform: scale(1) rotate(0);
    }
    40% {
        opacity: 0;
    }
}

@-webkit-keyframes slowFade {
    25% {
        opacity: 1;
        transform: scale(1) rotate(0);
    }
    40% {
        opacity: 0;
    }
}

.view {
  /* background-image: url("/src/assets/Zombies.png"); */
  /* background-image: url("/src/assets/hogwarts3.jpg"); */
  /* background-image: url("/src/assets/nether.jpg"); */
  background-attachment: fixed;
  background-repeat: no-repeat;
  height: 100%;
  border-bottom: 3px ridge rgb(128, 45, 2);
  background-size: cover;
  display: flex;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-image: url("/src/assets/dirt.jpg"); */
 background-color: rgba(18, 18, 18, 0.8);
  /* background-color: rgba(107, 162, 245, 0.547); */
  width: 50%;
  max-width: 450px;
  /* border: 2px solid white; */
  box-shadow: 3px 3px 10px rgb(128, 45, 2); 
  /* box-shadow: 3px 3px 10px black; */
}

p, ol, h1, h2 {
  /* color: #c7c7c7; */
  color: white;
}

p {
  font-size: 20px;
  margin-top: 0px;
}

ol {
  display: flex;
  flex-direction: column;  
  align-items: baseline;
}
</style>
