<template>
  <div id="crusader-games">
    <!-- <app-header id="app-header"></app-header> -->
    <main id="main-content">
      <router-view></router-view>
    </main>
    <!-- <app-footer id="app-footer"></app-footer> -->
  </div>
</template>

<script>
// import AppHeader from './components/AppHeader.vue';
// import AppFooter from './components/AppFooter.vue';


export default {
  name: 'App',
  // components: {
  //   AppHeader, 
  //   AppFooter
  // }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=MedievalSharp&display=swap');

#app {
  font-family: "MedievalSharp", cursive;
  font-weight: bold;
  text-align: center;
  position: relative;
  overflow: hidden;
}
</style>
