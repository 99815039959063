<template>
    <section class="view"></section>
</template>

<script>

</script>

<style scoped>

</style>
